<template>
  <div class="partyMember">
    <!-- <div class="tab">
      <div
        v-for="(item, index) in tabList"
        :key="index"
        :class="['item', { active: oIndex == index }]"
        @click="selectMem(item, index)"
      >
        {{ item.label }}
      </div>
    </div> -->
    <div class="main">
      <v-list
        :finished="finished"
        @onLoad="onLoad"
        ref="list"
        :finishedText="finishedText"
      >
        <div
          class="item"
          v-for="(item, index) in listData"
          :key="index"
          @click="toDetail(item, index)"
        >
          <div class="item-l">
            <div class="title">
              {{ item.topicTitle }}
            </div>
            <div class="item-lb">
              <div class="item-lbl">{{ item.createTime }}</div>
              <div class="item-lbr">
                <div class="img"><img src="./img/look.png" alt="" /></div>
                <div class="txt">{{ item.readCount }}</div>
              </div>
            </div>
          </div>
          <div class="item-r">
            <img :src="item.topicPicture" alt="" />
          </div>
        </div>
      </v-list>
    </div>
  </div>
</template>

<script>
import { gloabalCount } from "@/utils/common";
import wx from "weixin-js-sdk";
import { getTopicListURL, partyMemberDetailUrl } from "./api.js";
import { getHashParam } from "@/utils/utils.js";
var moment = require("moment");
export default {
  name: "partyMember",
  data() {
    return {
      fromFlag: "",
      oIndex: 0,
      tabList: [
        { label: "支委会", key: "7" },
        { label: "党员大会", key: "8" },
        { label: "党小组会", key: "9" },
        { label: "党课", key: "10" },
      ],
      finishedText: "没有更多了",
      finished: false,
      requestData: {
        curPage: 1,
        pageSize: 10,
      },
      listData: [],
    };
  },
  computed: {
    communityId() {
      return this.$store.state.communityId;
    },
    userId() {
      return this.$store.state.userId;
    },
    houseId() {
      return this.$store.state.houseId;
    },
    tenantId() {
      return this.$store.state.tenantId;
    },
  },
  created() {
    this.fromFlag = this.$route.query.fromFlag;
  },
  mounted() {
    gloabalCount("", 51, 1);
  },
  methods: {
    toAlink(item) {
      window.location.href = `tel://${item.mobile}`;
    },
    toDetail(item) {
      let params = {
        id: item.id,
        type: "主题党日",
      };
      if (this.fromFlag == "dingding") {
        this.$router.push({
          name: "partyTopic",
          query: {
            id: item.id,
          },
        });
      } else {
        this.$router.push({
          name: "topicTitle",
          query: {
            id: item.id,
            type: "主题党日",
          },
        });
        // if (wx.miniProgram) {
        //   wx.miniProgram.navigateTo({
        //     url: `/xubPages/partyInfoDetail/index?params=${JSON.stringify(
        //       params
        //     )}`,
        //   });
        // }
      }
    },
    selectMem(item, index) {
      this.oIndex = index;
      this.finished = false;
      this.finishedText = "没有更多了";
      this.$refs.list._data.loading = true;
      this.requestData.curPage = 1;
      this.listData = [];
      this.getPartyList(item.key);
    },
    onLoad() {
      this.getPartyList(11);
    },
    async getPartyList(topicTypes) {
      let params = {
        tenantId: this.tenantId,
        userId: this.userId,
        houseId: this.houseId || this.communityId || undefined,
        status: 1, // 0：待审核；1：审核通过；2：审核不通过；3：已关闭；4：删除
        topicType: 11, // 1. 和伙人风采 2.超话 3. 活动 7：支委会；8：党员大会；9：党小组会；10：党课；11：主题党日
        curPage: this.requestData.curPage,
        pageSize: this.requestData.pageSize,
        sortField: "NEW",
      };
      let res = await this.$axios.get(`${getTopicListURL}`, { params: params });
      console.log(res, 333);
      if (res.code === 200) {
        if (res.data.pages <= res.data.current) {
          this.finished = true;
        } else {
          this.$refs.list._data.loading = false;
        }
        this.finishedText = "没有更多了";
        if (res.data.records.length === 0) {
          this.finishedText = "";
          return;
        }
        res.data.records.forEach((item) => {
          this.listData.push(item);
        });
        this.listData.forEach((ele) => {
          if (ele.topicPicture) {
            ele.topicPicture = this.$handleImg(226, 150, ele.topicPicture);
          }
        });
      }
    },
  },
};
</script>

<style lang="less" scoped>
.partyMember {
  min-height: 100vh;
}
.partyMember {
  .tab {
    display: flex;
    justify-content: space-between;
    background: #fff;
    width: 100%;
    position: fixed;
    top: 0;
    left: 0;
    box-sizing: border-box;
    z-index: 999;
    .item {
      flex: 1;
      text-align: center;
      padding: 28px 0;
      font-size: 28px;
      font-weight: 400;
      color: #323334;
      line-height: 40px;
    }
    .active {
      position: relative;
      font-weight: 700;
      &::after {
        content: "";
        position: absolute;
        width: 64px;
        height: 8px;
        background: #007eff;
        border-radius: 4px;
        bottom: 0;
        left: 0;
        right: 0;
        margin: auto;
      }
    }
  }
  .main {
    .item {
      padding: 20px 30px;
      display: flex;
      align-items: center;
      justify-content: space-between;
      border-bottom: 1px solid rgba(0, 0, 0, 0.06);
      .item-l {
        flex: 1;
        margin-right: 38px;
        .title {
          font-size: 32px;
          font-weight: 400;
          color: rgba(0, 0, 0, 0.5);
          line-height: 44px;
          margin-bottom: 28px;
        }
        .item-lb {
          display: flex;
          align-items: center;
          justify-content: space-between;
          .item-lbl {
            font-size: 24px;
            font-weight: 400;
            color: #b4b4b4;
            line-height: 34px;
          }
          .item-lbr {
            display: flex;
            align-items: center;
            .img {
              width: 28px;
              height: 26px;
              margin-right: 4px;
              font-size: 0;
              img {
                width: 100%;
                height: 100%;
              }
            }
            .txt {
              font-size: 24px;
              font-weight: 400;
              color: #b4b4b4;
              line-height: 34px;
            }
          }
        }
      }
      .item-r {
        width: 226px;
        height: 150px;
        border-radius: 8px;
        img {
          width: 100%;
          height: 100%;
          border-radius: 8px;
        }
      }
    }
  }
}
</style>
